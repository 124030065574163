import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class RoomService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/room';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getByVenue(data={},index = null, venueId) {
        let url = `admin/venue/${venueId}/rooms`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    all(data={}){

        let url = `${this.#api}/get/all`;
        let param ={
            params: data
        }
        return apiService.query(url,param)
    }

    getAllByVenue(venueId) {
        let url = `${this.#api}/venue/all/${venueId}/rooms`
        return apiService.get(url)
    }


}